<template>
  <div class="new_major_list">
    <div class="title_school_name" v-if="currentSchoolData">
      {{ currentSchoolData.name }}
    </div>
    <div class="major_list_wrap">
      <div v-for="(item, sIndex) in this.tableData" :key="sIndex">
        <ul class="major_list" v-if="item.id == currentSchoolData.id">
          <li v-for="(majorItem, mindex) in item.professionals" :key="mindex">
            <div class="item" v-if="majorItem.can_edit">
              <div class="info">
                <div class="info_head">
                  <div class="school_info">
                    <span class="school_name">{{ majorItem.name }}</span>
                  </div>
                  <div class="major_btn">
                    <span
                      class="major_num selected_major"
                      v-if="majorItem.isSelect"
                      @click="handleClickFillBtn(item, majorItem)"
                    >
                      已填报
                    </span>
                    <span
                      v-else
                      class="major_num"
                      @click="handleClickFillBtn(item, majorItem)"
                    >
                      填报
                    </span>
                  </div>
                </div>
                <div class="gray_label">
                  <span class="category">{{ majorItem.years }}年</span>
                  <span class="major_money">{{ majorItem.money }}</span>
                  <span>{{ majorItem.require_subjects }}</span>
                  <span class="major_code">专业代码 {{ majorItem.code }}</span>
                </div>
              </div>
              <!-- <div class="table_title">
                {{ currentYear }}计划数
                <span v-if="majorItem.data.length > 0">{{
                  majorItem.data[0].new_counts
                }}</span>
              </div> -->
              <div class="my_table">
                <ul class="column_head">
                  <li></li>
                  <li><span>计划数</span></li>
                  <!-- <li><span>录取数</span></li> -->
                  <li><span>最低分</span></li>
                  <li><span>最低位次</span></li>
                </ul>
                <ul class="history_table">
                  <li>
                    <span v-for="(val, vindex) in majorItem.data" :key="vindex">
                      <template v-if="val.year">
                        {{ val.year }}
                      </template>
                    </span>
                  </li>
                  <li>
                    <span
                      v-for="(val, vindex) in majorItem.data"
                      :key="vindex"
                      >{{ val.new_counts }}</span
                    >
                  </li>
                  <!-- <li>
                    <span
                      v-for="(val, vindex) in majorItem.data"
                      :key="vindex"
                      >{{ val.people_count }}</span
                    >
                  </li> -->
                  <li>
                    <span
                      v-for="(val, vindex) in majorItem.data"
                      :key="vindex"
                      >{{ val.min_points }}</span
                    >
                  </li>
                  <li>
                    <span
                      v-for="(val, vindex) in majorItem.data"
                      :key="vindex"
                      >{{ val.min_position }}</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="bottom_btns">
      <preview-zhiyuan-btn :tableData="tableData"></preview-zhiyuan-btn>
      <buy-vip
        v-if="userInfo && !userInfo.can_query"
        :reload="reload"
        :routePath="routePath"
        :vipType="vipType"
      ></buy-vip>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import buyVip from "@/components/buyVip";
import previewZhiyuanBtn from "@/components/previewZhiyuanBtn";

export default {
  components: { buyVip, previewZhiyuanBtn},
  data() {
    return {
      vipType: 2,
      majorDetailPopupShow: false,
      currentSchoolData: {
        name: "",
        professionals: []
      },
      fillDataList: [],
      majorDetail: {},
      schoolDetail: {},
      majorNowYear: "",
      majorYears: [],
      tableData: [],
      subTableData: [],
      copyTableData: [],
      reload: false,
      routePath: "",
      selectTotalNum: 0
    };
  },

  computed: {
    ...mapState({
      userInfo: state => state.userinfo,
      currentProvince: state => state.currentProvince,
      currentYear: state => state.currentYear,
      currentType: state => state.currentType
      // isAuthed: state => (state.userinfo ? true : false),
      // notify: state => state.notify,
      // favorates: state => state.favorates,
      // histories: state => state.histories
    })
  },
  watch: {
    userInfo: function(newVal) {
      if (newVal && this.majorYears.length == 0) {
        this.getMajorYears();
      }
    }
  },
  mounted() {
    this.routePath = "/simulatedFill/new";
    this.tableData = JSON.parse(localStorage.getItem("allSchoolData"))|| [];
    if (this.copyTableData.length == 0) {
      this.copyTableData = JSON.parse(localStorage.getItem("allSchoolData"))|| [];
    }
    this.currentSchoolData = JSON.parse(
      localStorage.getItem("currentSchoolData")
    )|| [];
    console.log("all---", this.tableData);
    console.log("currentSchoolData----", this.currentSchoolData);
    this.getMajorYears();
  
  },
  created() {},
  methods: {
    
    async getMajorYears() {
      if (this.userInfo && this.userInfo.province) {
        const apiUrl =
          "/api/year/professional/?province=" + this.userInfo.province;
        const response = await this.$axios.get(apiUrl);
        if (response.data.errno === 0) {
          // 填报限制
          this.majorNowYear = response.data.results.now_year + "";
          this.majorYears = response.data.results.years;
        }
      }
    },
    handleClickFillBtn(item, majorItem) {
      this.schoolDetail = item;
      this.majorDetail = majorItem;
      this.majorDetail.isSelect = !this.majorDetail.isSelect;
      
      //   let itemIndex;
      //   const flag = this.fillDataList.find((el, index) => {
      //     if (el.id == majorItem.id) {
      //       itemIndex = index;
      //     }
      //     return el.id == majorItem.id;
      //   });
      //   if (!flag) {
      //     this.fillDataList.push(majorItem);
      //   } else {
      //     this.fillDataList.splice(itemIndex, 1);
      //   }

      localStorage.setItem("allSchoolData", JSON.stringify(this.tableData));

      console.log("数据---", this.tableData);
    },
    
  }
};
</script>

<style lang="scss" scoped>
.new_major_list {
  min-height: 100vh;
  background: #e8e8e8;
  padding-top: 0.8rem;
  .bottom_btns {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 0 0.3rem;
    height: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .title_school_name {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    height: 0.8rem;
    line-height: 0.8rem;
    font-weight: 600;
    font-size: 0.3rem;
    padding: 0 0.3rem;
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
  }
  .major_list_wrap {
    padding-bottom: 3rem;
    .table_title {
      margin: 20px 0 10px 0;
      color: #444;
    }
    .my_table {
      display: flex;
      margin-top: 0.3rem;
      .column_head {
        flex-basis: 26%;
        li {
          height: 30px;
        }
      }

      ul.history_table {
        flex-basis: 74%;
        li {
          height: 30px;
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  ul.major_list {
    font-size: 0.22rem;
    > li {
      width: 100%;
      text-align: left;
      background: #fff;
      color: #999;
      overflow: hidden;
      box-sizing: border-box;
      margin-bottom: 10px;
      &:last-child {
        border: none;
      }
      .school_name{
        color: #e04a00;
        font-size: 0.26rem;
      }
      .item {
        padding: 0.3rem;
      }
      .info_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.1rem;
        .school_info {
          flex-basis: 78%;
        }
      }
      .gray_label {
        font-size: 0.2rem;
        .major_code {
          margin-left:10px;
        }
      }
      .major_money {
        margin: 0 10px;
      }
      .school_name {
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .line {
        margin: 0 10px;
      }
      .people_count {
        margin-left: 20px;
      }
      .major_btn {
        border: 1px solid #e5e5e5;
      }
      .major_btn,
      .major_num {
        display: inline-block;
        text-align: center;
        background: #fff;
        border-radius: 15px;
      }
      .major_num {
        width: 66px;
        padding: 2px 0;
      }
      .select_zhiyuan {
        width: 66px;
        text-align: center;
        color: #910000;
        font-size: 12px;
        margin-top: 2px;
      }
      .selected_major {
        background: #910000;
        color: #fff;
      }
    }
  }
}
</style>
