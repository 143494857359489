<template>
  <div class="preview_fill_list" :class="{'vip_preview_btn': userInfo && userInfo.can_query}" @click="viewFillDetail">
    预览志愿
    <span>{{ selectTotalNum }}</span>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    tableData: {
      type: Array,
      default: []
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userinfo,
      currentProvince: state => state.currentProvince,
      currentYear: state => state.currentYear,
      currentType: state => state.currentType
      // isAuthed: state => (state.userinfo ? true : false),
      // notify: state => state.notify,
      // favorates: state => state.favorates,
      // histories: state => state.histories
    })
  },
  data() {
    return {
      selectTotalNum: 0
    };
  },
  watch: {
    tableData: {
      deep: true,
      handler: function() {
        this.calcSelectNum();
      }
    }
  },
  mounted() {
    this.calcSelectNum();
  },
  methods: {
    calcSelectNum() {
      localStorage.removeItem("selectTotalNum");
      this.selectTotalNum = 0;
      this.tableData.forEach(i => {
        if (i.professionals && i.professionals.length > 0) {
          i.professionals.forEach(p => {
            if (!!p.isSelect) {
              this.selectTotalNum += 1;
            }
          });
        }
      });

      localStorage.setItem("selectTotalNum", this.selectTotalNum);
      console.log("this.selectTotalNum", this.selectTotalNum);
    },
    viewFillDetail() {
      this.$router.push({ name: "NewPreviewTable" });
    }
  }
};
</script>

<style lang="scss" scoped>
.preview_fill_list {
  font-size: 13px;
  width: 50%;
  height: 0.8rem;
  border: 1px solid #910000;
  background: #fff;
  color: #910000;
  padding: 0 0.34rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 100px;
  margin-right: 0.2rem;
  span {
    margin-left: 10px;
    font-weight: 600;
  }
}

.vip_preview_btn{
  width: 100%;
}
</style>
