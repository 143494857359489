<template>
  <div class="buy_vip_component">
    <div class="buy_vip" @click="showModal = true">绑定VIP查看全部</div>
    <cardBind
      :showModal="showModal"
      @closeModal="hideModal"
      :reload="reload"
      :routePath="routePath"
      :vipType="vipType"
    ></cardBind>
  </div>
</template>

<script>
import cardBind from "@/components/cardBind";
export default {
  components: { cardBind },
  data() {
    return {
      showModal: false,
    };
  },
  props: {
    reload: {
      type: Boolean,
      default: false
    },
    routePath: {
      type: String,
      default: "/"
    },
    vipType: {
      type: Number,
      default: 1
    }
  },
  methods: {
    created() {
      // console.log("-----", this.$route.path);
    },
    hideModal() {
      this.showModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.buy_vip_component {
  width: 100%;

  .buy_vip {
    font-size: 13px;
    width: 100%;
    height: 0.8rem;
    background: #910000;
    color: #fff;
    padding: 0 0.34rem;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 100px;
    text-align: center;
  }
}
</style>
